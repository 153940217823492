import React, { createContext, useEffect, useReducer, useState } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import AppLoader from "components/shared/appLoader/AppLoader";
import { ADMIN_ROLE, ADMIN__ROLE} from "utils/constant";
import AlertDialogSlide from "components/dialog/DialogBox";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
    axios.defaults.headers.common['Client-Id'] = 'WAYAQUICK';
    axios.defaults.headers.common['Client-Type'] = 'ADMIN';
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userDetails: null
      };
    }

    case "FORGOT-PASSWORD": {
      const { user } = action.payload;
      return {
        ...state,
        user: user,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  forgotPassword: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userDetails, setuserDetails] = useState(state?.user);
  const [userPermissions, setUserPermissions] = useState([]);
  const { REACT_APP_BASE_URL: baseUrl } = process.env;
  const [dialogData, setDialogData] = React.useState({ user: {}, open: false });

  const login = async (payload) => {
    const response = await axios.post(`${baseUrl}/api/v1/auth/login`, payload);
    const {
      data: { token, user, roles },
    } = response.data;
    console.log(roles)
    let idx = payload?.emailOrPhoneNumber.indexOf('@wayapaychat.com');

    if (!user?.isAdmin || idx < 0 ) {
      setDialogData({ user: user, open: true });
      return;
    }
    setSession(token);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const verifyOtp = async (payload) => {
    const response = await axios.post(
      `${baseUrl}/api/v1/auth/verify-otp`,
      payload
    );
    return response;
  };

  const forgotPassword = async (payload) => {
    const response = await axios.get(
      `${baseUrl}/api/v1/password/change-password/byEmail?email=${payload.email}`
    );
    dispatch({
      type: "FORGOT-PASSWORD",
      payload,
    });
    return response;
  };

  const changePassword = async (payload) => {
    const response = await axios.get(`${baseUrl}/api/v1/change-password`);
    dispatch({
      type: "CHANGE-PASSWORD",
      payload,
    });
    return response;
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          setSession(null);
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <AppLoader />;
  }
  const { user, open } = dialogData;
  return (
    <>
      {open && (
        <AlertDialogSlide
          open={open}
          user={user}
          handleClose={() =>
            setDialogData((data) => ({ ...data, open: false }))
          }
        />
      )}
      <AuthContext.Provider
        value={{
          ...state,
          method: "JWT",
          login,
          logout,
          forgotPassword,
          changePassword,
          verifyOtp,
          userDetails,
          setuserDetails,
          userPermissions,
          setUserPermissions
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
