import axios from "axios";
import CONFIG from "../../config";
import { saveAs } from "file-saver";
import { getQueryString } from "utils/utilFn";

const baseURL = `${CONFIG.BASE_URL}/auth-service/api/v1/admin`;

const baseUser = `${CONFIG.BASE_URL}/auth-service/api/v1/user`;
const basePayment = `${CONFIG.BASE_URL}/payment-gateway/api/v1`;
const baseSettlement = `${CONFIG.BASE_URL}/identity-manager-service/api/v1`
const baseSettlement1 = `${CONFIG.BASE_URL}/wayapay-settlement-service/api/v1`
const baseBusiness = `${CONFIG.BASE_URL}/auth-service/api/v1/business`;
const dashboardUrl = `${CONFIG.BASE_URL}/auth-service/api/v1/dashboard`;
const adminRoleUrl = `${CONFIG.BASE_URL}/wayapay-role-access/admin`;
const adminRoleUrl1 = `${CONFIG.BASE_URL}/wayapay-role-access/admin`;
const requestLogUrl = `${CONFIG.WAYABANK_URL}/activity-logservice`
const baseIdentity = `${CONFIG.BASE_URL}/identity-manager-service/api/v1`;



export const getAllUsers = async ({ page, size }) => {
  const queryString = getQueryString(page, size);
  return await axios
    .get(`${baseURL}/users/corporate-profile${queryString ?? ""}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log("err fetching");
      // throw new Error(err);
    });
};

export const getAllRequestLog = async ({ page, size }) => {
  return await axios
    .get(`${requestLogUrl}/api/v1/requestlog/list`)
    .then((res) => res.data)
    .catch((err) => {
      console.log("err fetching");
      // throw new Error(err);
    });
};


export const getAllStat = async ({ page, size }) => {
  return await axios
    .get(`${requestLogUrl}/api/v1/requestlog/total-request`)
    .then((res) => res.data)
    .catch((err) => {
      console.log("err fetching");
      // throw new Error(err);
    });
};

export const createRequest = async (pojo) =>
await  axios
    .post(`${requestLogUrl}/api/v1/requestlog/create`, pojo)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        // Handle else
      }
    });
    export const UploadBulkRequest = ({file}) => { 
      let d = new Date();
      let reqId= d.getTime();
        let formData = new FormData();
      formData.append("file", file);
      formData.append("customerName", 'Bulk Users');
      formData.append("requestId", d.getTime());
      formData.append("requestType", 'createBulkMerchant'); 
      
      return axios
        .post(`${requestLogUrl}/api/v1/requestlog/create/bulk/${reqId}/Bulk Users/createBulkMerchant`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })    
        .then((res) => res.data)
        .catch((err) => {
          throw new Error(err.response.data.message);
        });
    };

    export const processRequest = (pojo) =>
    axios
      .post(`${requestLogUrl}/api/v1/requestlog/process-request `, pojo)
      .then((res) => res.data)
      .catch((err) => {
        if (err.response.status === 400) {
          return err.response;
        } else {
          // Handle else
        }
      });
  
      export const updateProcessor = ({id, data}) =>
      axios
        .post(`${basePayment}/processors/${id} `, data)
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.status === 400) {
            return err.response;
          } else {
            // Handle else
          }
        });
      export const toggleProcessor = ({id, isActive}) =>
        axios
          .put(`${basePayment}/processors/${id}/enable?isActive=${isActive}`)
          .then((res) => res.data)
          .catch((err) => {
            if (err.response.status === 400) {
              return err.response;
            } else {
              // Handle else
            }
          });
      export const enableProcessor = ({id, isActive, routingType}) =>
        axios
          .put(`${basePayment}/processors/routingType/${id}/enable?isActive=${isActive}&routingType=${routingType}`)
          .then((res) => res.data)
          .catch((err) => {
            if (err.response.status === 400) {
              return err.response;
            } else {
              // Handle else
            }
          });
export const createUsers = (pojo) =>
  axios
    .post(`${baseURL}/users/create-corporate`, pojo)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        // Handle else
      }
    });

export const getMerchant = async (userId) =>
  await axios
    .get(`${baseURL}/users/corporate-profile/merchantID/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));

export const getAllAdminUsers = (page = 0, rowsPerPage = 10) =>
  axios
    .get(`${baseURL}/users?page=${page}&size=${rowsPerPage}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));

export const getUserStatus = async () =>
  await Promise.all([
    axios.get(`${dashboardUrl}/active-users`),
    axios.get(`${dashboardUrl}/in-active-users`),
    axios.get(`${dashboardUrl}/total-registered-users`),
  ])
    .then((res) => res.map(({ data }) => data))
    .catch((err) => console.log(err));

export const UploadBulkUser = ({ file, isCorporate = true }) => {
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(`${baseURL}/users/bulk-user-excel/${isCorporate}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    .then((res) => res.data.data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const UploadBulkUserDeactivate = ({ file, isCorporate = true }) => {
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(`${baseURL}/users/bulk/account-deactivation`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const deactivateUser = async (userId) =>
  await axios
    .post(`${baseURL}/users/activation-toggle?userId=${userId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error({ message: err });
    });

export const downloadTemplate = async ({ isCorporate }) => {
  await axios
    .get(`${baseURL}/download/bulk-user-excel?isCorporate=${isCorporate}`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "bulk-user-excel.xlsx");
    })
    .catch((err) => console.log(err));
};

export const getUniqueCell = ({
  cellName,
  allData,
  uniqueCells = "otherDetails.organizationCity",
}) => {
  if (typeof uniqueCells === "object") {
    return uniqueCells;
  }
  const keyArr = allData
    // eslint-disable-next-line
    ?.map((i) => eval(`i.${uniqueCells}`))
    ?.map((i) => i?.toUpperCase()?.trim());
  return [...new Set(keyArr)];
};

export const getUserById = (userId = "") =>
  axios
    .get(`${baseUser}/${userId}`)
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });

export const getMerchantById = async ({ userId }) =>
  await axios
    .get(`${baseURL}/users/corporate-profile/merchantID/${userId}`)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response?.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });

  export const getMerchantId = async (userId) =>
    await axios
      .get(`${baseIdentity}/waya-merchant/${userId}`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.response?.status === 400) {
          return err.response.data;
        } else {
          console.log(err);
          // Handle else
        }
      });

export const getMerchantWallet = async (userId ) =>
  await axios 
    .get(`${basePayment}/wallet/accounts/${userId}`)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response?.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });

    export const getMerchantAccounts = async (merchantId ) =>
    await axios 
      .get(`${baseSettlement}/waya-merchant/settlement-bank-account/fetch-all/admin/${merchantId}`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.response?.status === 400) {
          return err.response.data;
        } else {
          console.log(err);
          // Handle else
        }
      });
    
      export const settleManually = async ({refId}) =>
      await axios
        .post(`${baseSettlement1}/transactions/settlements/doManualByAdmin?refNo=${refId} `, {})
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.status === 400) {
            return err.response;
          } else {
            return err
            // Handle else
          }
        });

export const updateUser = ({ userId, updateData }) =>
  axios
    .put(`${baseURL}/update-profile/${userId}`, updateData)
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });

export const getBusinessType = async ({ page, size }) => {
  const queryString = getQueryString(page, size);
  return await axios
    .get(`${baseBusiness}/type/find/all${queryString ?? ""}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getBusinessProfileById = ({ userId }) => {
  return axios
    .get(`${baseURL}/users/corporate-profile/${userId}`)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response?.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });
};
