import _ from "lodash";
import { getDisputeList } from "services/https/disputeService";
import { getAllUsers } from "services/https/fetchers";
import { getAllPricing } from "services/https/pricingFetcher";
import {
  getSettlementList,
  getTransactionList,
} from "services/https/transactionFetchers";
import { getBusinessType, getKycList } from "services/https/updatedKYCService";
const Fuse = require("fuse.js").default;

export const customSearch = async (data, query, keys = []) =>
  await new Promise((resolve, reject) => {
    if (!_.isEmpty(query)) {
      try {
        let optKeys = !_.isEmpty(keys) ? keys : data && Object.keys(data[0]);
        const fuse = new Fuse(data, {
          threshold: 0.1,
          keys: optKeys,
          includeMatches: false,
          includeScore: true,
          minMatchCharLength: query.length,
        });
        resolve(fuse.search(query));
      } catch (error) {
        console.error("rejection", error);
      }
    } else {
      resolve(data);
    }
  });

export const searchData = {
  transaction: () => getTransactionList({ page: 0, size: 10 }),
  settlement: () => getSettlementList({ page: 0, size: 10000000 }).then((data) => data),
  dispute: () => getDisputeList({ size: 10000000 }).then((data) => data),
  manageUser: async () => {
    const data = await getAllUsers({ size: 100000000 });
    return data?.users;
  },
  pricing: async () => {
    const data = await getAllPricing({ size: 100000000 });
    return data?.content;
  },
  kycList: async () => {
    const data = await getKycList({ size: 10000000 });
    return data;
  },
  businessIndustry: async () => {
    const data = await getBusinessType({ size: 10000000 });
    return data;
  },
};
