import React, { useEffect, useRef } from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Popover,
  IconButton,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import useMenu from "hooks/useMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  DEACTIVATE_USER,
  MANAGE_KYC,
  MANAGE_TEAM_MEMBERS,
  MANAGE_USER_PROFILE,
  PERFORM_TRANSACTION,
} from "utils/constant";
import { useDispatch } from "react-redux";
import {
  createDialog,
  notifyFailure,
  notifySuccess,
} from "redux/actions/AlertActions";
import { useNavigate } from "react-router-dom";
import { createRequest, deactivateUser, getMerchant, getMerchantById, getMerchantId } from "services/https/fetchers";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function UserTableMenu({
  open,
  id,
  setOpenDialog,
  handleClose,
  anchorEl,
  setAnchorEl,
  row,
}) {
  const dispatch = useDispatch();
  const { setSelectedAction, selectedAction } = useMenu();
  const currRow = useRef();
  const handleClick = (e) => {
    setAnchorEl(e.target);
  };
  const navigate = useNavigate();
  const handleListItemClick = (e, item, row) => {
    currRow.current = row;
    setSelectedAction({ action: item, user: row });
  };

  const handleDeactivateOk = async () => {
   // await deactivateUser(selectedAction.user?.id)
   let d = new Date();
   let payload = {
    customerName: `${row?.organizationName}`,
    payload: JSON.stringify({ userId: selectedAction?.user?.id }),
    requestId: d.getTime(),
    requestType: "activateDeactivateMerchant"
  }
    await createRequest()
      .then((res) => {
        dispatch(
          notifySuccess({
            msg: `User ${selectedAction.user?.organisationName} deactivated, waiting for approval`,
          })
        );
      })
      .catch((err) => {
        dispatch(
          notifyFailure({
            msg: `User ${selectedAction.user?.organisationName} deactivation failed`,
          })
        );
      });
    setOpenDialog(false);
  };
  const payload = {
    title: "Confirm Deactivate Action",
    subTitle: "Are you sure you want to deactivate user",
    handleOk: handleDeactivateOk,
  };

  useEffect(() => {
    if (selectedAction?.action === DEACTIVATE_USER) {
      dispatch(createDialog({ type: "confirmation", payload }));
      setOpenDialog(true);
    }
    if (selectedAction?.action === PERFORM_TRANSACTION) {
      navigate(`merchants/${selectedAction.user.id}/perform-transaction`);
    }
    if (selectedAction?.action === MANAGE_USER_PROFILE) {
      navigate(`${selectedAction.user.id}/profile`);
    }
    if (selectedAction?.action === MANAGE_TEAM_MEMBERS) {
      navigate(`${selectedAction.user.id}/teams`);
    }
    if (selectedAction?.action === MANAGE_KYC) {
      console.log(selectedAction, "dt");
      getMerchantId(selectedAction.user.id).then((data) => {
        console.log({ data })
        navigate(`/kyc/merchant/${selectedAction.user.id}`);
      });
    }

    return () => {
      handleClose();
      setSelectedAction("");
    };
  }, [selectedAction?.action, currRow.current?.id]);

  const menuListItem = [
    "Manage Profile",
    "Manage Team Members",
    "Perform Transactions",
    "Manage KYC",
    "Login Tracker",
    "Activitiy Log",
    "Reset Credentials",
    "Deactivate User",
    "Delete User",
  ];
  return (
    <>
      <StyledTableCell align="right" sx={{ paddingRight: "2em" }}>
        {
          <>
            <IconButton aria-describedby={id} onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </>
        }
      </StyledTableCell>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          {menuListItem.map((i, idx) => (
            <MenuItem key={`menu-${idx}`}>
              <ListItemText onClick={(e) => handleListItemClick(e, i, row)}>
                {i}
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
}
