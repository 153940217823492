import axios from "axios";

// axios instance for making requests
const axiosInstance = axios.create({
  headers: {
    Authorization: `${localStorage.getItem("accessToken")}`,
    'Client-Id': 'WAYAQUICK',
    'Clien-Type': 'Admin' 
  },
});

/* axiosInstance.interceptors.response.use(() => {
  console.log("resp log: ", "fetching");
});
 */
export default axiosInstance;
